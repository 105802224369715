input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
}

textarea {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  height: inherit;
  line-height: normal;
  font-size: 12px;
  padding: 0.175rem 0.55rem;
  color: #252422 !important;
  outline: none;
}

.footer {
  position: absolute;
  right: 25px;
  bottom: 20px;
  z-index: inherit;
}

.fa-sm {
  font-size: 12px !important;
}

.nc-sm {
  font-size: 12px !important;
  line-height: 20px !important;
}

.sidebar, .sidebar-wrapper {
  width: 220px !important;
}

.off-canvas-sidebar[data-color=default]:after, .sidebar[data-color=default]:after {
  background: #66615b;
}

.off-canvas-sidebar .sidebar-wrapper .user .info [data-toggle=collapse]~div>ul>li>a .sidebar-normal, .off-canvas-sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal, .sidebar .sidebar-wrapper .user .info [data-toggle=collapse]~div>ul>li>a .sidebar-normal, .sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
  text-transform: capitalize;
}

.off-canvas-sidebar .nav p, .sidebar .nav p {
  margin: 0;
  line-height: 30px;
  position: relative;
  display: block;
  height: auto;
  white-space: nowrap;
  font-weight: 600;
}

.off-canvas-sidebar .sidebar-wrapper .user .info [data-toggle=collapse]~div>ul>li>a .sidebar-mini-icon, .off-canvas-sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-mini-icon, .sidebar .sidebar-wrapper .user .info [data-toggle=collapse]~div>ul>li>a .sidebar-mini-icon, .sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-mini-icon {
  text-transform: uppercase;
  width: 34px;
  margin-right: 10px;
  margin-left: 0;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  position: relative;
  float: left;
  z-index: 1;
  display: inherit;
  color: hsla(0,0%,100%,.5);
}

.off-canvas-sidebar .sidebar-wrapper .user .info [data-toggle=collapse]~div>ul>li>a .sidebar-normal, .off-canvas-sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal, .sidebar .sidebar-wrapper .user .info [data-toggle=collapse]~div>ul>li>a .sidebar-normal, .sidebar .sidebar-wrapper>.nav [data-toggle=collapse]~div>ul>li>a .sidebar-normal {
  margin: 0;
  position: relative;
  transform: translateX(0);
  opacity: 1;
  white-space: nowrap;
  display: block;
  line-height: 20px;
  z-index: 1;
}

.off-canvas-sidebar .nav li>a, .sidebar .nav li>a {
  margin: 10px 15px 0;
  color: #fff;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  padding: 10px 8px;
  line-height: 30px;
  opacity: .7;
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 220px);
  background-color: #f4f3ef;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

.main-panel>.content {
  padding: 0 30px 30px;
  min-height: calc(100vh - 160px);
  margin-top: 93px;
}

.navbar.navbar-transparent {
  background-color: transparent!important;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
}

.dropdown-menu {
  border-radius: 5px;
}

.dropdown-item.active {
  background-color: transparent;
  color: #ef8157!important;
}

.dropdown-item img, .dropdown-toggle img {
  margin-right: 10px;
  width: 24px;
}

.navbar-nav .dropdown-menu:before {
  top: -11px;
}

.dropdown-menu .dropdown-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.dropdown-menu .dropdown-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.navbar.navbar-transparent a:not(.dropdown-item):not(.btn) {
  color: #66615b;
}

.navbar.navbar-transparent .nav-item .nav-link:not(.btn) {
  color: #66615b;
}

.btn-magnify i, .btn-move-left i, .btn-move-right i, .btn-rotate i, .icon-property {
  transition: all .37s cubic-bezier(.34,1.61,.7,1);
  position: relative;
  display: inline-block;
}

.nav-top {
  cursor: pointer;
}

.nav-top:hover, .nav-top:active, .nav-top:focus {
  color: #ef8157 !important;
}

.table>thead>tr>th {
  border: 1px solid #dee2e6;
}

.table>thead>tr>th.sortable {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.caret-up {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-bottom: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.caret-down {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.table-responsive .table-small th, .table-responsive .table-small td {
  font-size: 12px;
  padding: 3px 3px;
}

.table-responsive .table-small .form-control {
  font-size: 12px;
  padding: 0.175rem 0.55rem;
}

.btn-xsm {
  padding: 3px 5px;
  font-size: 8px;
}

button.close {
  border: 0;
  color: #FFFFFF;
  opacity: 1;
  text-shadow: none;
  outline: 0;
  font-size: 16px;
}

button.close:hover {
  color: #FFFFFF;
  text-shadow: 1px 0 #FFFFFF;
}

.progressBarRoot {
  height: 1px;
  overflow: hidden;
  position: relative;
  background-color: #ffc483;
}

.progressBarRoot.progressBarInvisible {
  height: 1px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
}

.progressBarRoot > hr {
  margin: 0;
}

.progressBar {
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  position: absolute;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: #fe7433;
}

@keyframes indeterminate1 {
  0% {
    left: -100%;
    right: 100%
  }
  60% {
    left: 100%;
    right: -90%
  }
  100% {
    left: 100%;
    right: -90%
  }
}

@keyframes indeterminate2 {
  0% {
    left: -200%;
    right: 100%
  }
  60% {
    left: 107%;
    right: -8%
  }
  100% {
    left: 107%;
    right: -8%
  }
}

.bar1Indeterminate {
  width: auto;
  will-change: left, right;
  animation: indeterminate1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.bar2Indeterminate {
  width: auto;
  will-change: left, right;
  animation: indeterminate2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 1.15s;
}

.modal.show {
  background:rgba(0, 0, 0, 0.5);
}

.text-md {
  font-size: 14px;
}

.text-lg {
  font-size: 18px;
}

.text-xlg {
  font-size: 20px;
}

.text-sm {
  font-size: 12px;
}

.text-xsm {
  font-size: 10px;
}

.card {
  border-radius: 5px;
}

.card-header:first-child {
  border-radius: 5px 5px 0 0!important;
}

.device-list {
  position: fixed;
  height: 100%;
  width: 300px;
  top: 0;
  bottom: 0;
  overflow: scroll;
}

.device-view {
  position: relative;
  float: right;
  width: calc(100% - 300px);
}

.card-footer.device .nav-tabs.bottom .nav-item {
  margin-right: 8px;
}

.card-footer.device .nav-tabs-navigation.bottom {
  padding-top: 8px;
}

.header-device {
  padding-bottom: 5px!important;
  padding-top: 5px!important;
}

.card-stats .card-body.device {
  padding: 8px 8px 8px 8px;
}

.device-icon-wrapper {
  height: 36px;
  width: 36px;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.device-circle {
  border-radius: 30px;
  border: 2px solid #51bcda;
}

.device-circle.danger {
  border-radius: 30px;
  border: 2px solid #ef8157;
}

.device-icon {
  height: 32px;
  width: 32px;
  display: block;
}

.device-icon.lock.open {
  background: url('../img/app/icons8-private-lock-32.png');
}

.device-icon.lock.closed {
  background: url('../img/app/icons8-check-lock-32.png');
}

.device-icon.lock.delete {
  background: url('../img/app/icons8-delete-lock-32.png');
}

.device-icon.switch.on {
  /*background: url('../img/app/icons8-switch-on-32.png');*/
  background: url('../img/app/icons8-toggle-on-32.png');
}

.device-icon.switch.off {
  /*background: url('../img/app/icons8-switch-off-32.png');*/
  background: url('../img/app/icons8-toggle-off-32.png');
}

.device-icon.t_wire {
  background: url('../img/app/icons8-temperature-32.png');
}

.btn-device {
  position: absolute;
  height: 70px;
  width: 70px;
  top: 6px;
  right: 25px;
  border-style: solid;
  border-radius: 50px;
  border-width: 5px;
  border-color: #fff !important;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.fa-cog.fa-spin {
  padding-top: 0;
}

.stable-font {
  font-family: "Courier", "Helvetica", "Arial", sans-serif;
}

.device-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.device-menu a {
  display: flex;
  align-items: center;
  color: #66615b;
}

.device-menu a.active {
  color: #ef8157;
}

.device-menu a:hover {
  color: #ef8157;
  text-decoration: none;
}

@keyframes scroll {
  from {
    margin-bottom: 0;
  }

  to {
    margin-bottom: 50%;
  }
}

.fa-scroll-up {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scroll;
}

.fa-scroll-down {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: reverse;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: scroll;
}

.graph-tooltip {
  background: white;
  padding: 9px 0 9px 0;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.15);
}

.graph-tooltip .container {
  white-space: pre;
  display: flex;
  align-items: center;
}

.graph-tooltip .color {
  display: block;
  width: 12px;
  height: 12px;
  margin-right: 7px;
}
